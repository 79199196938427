// extracted by mini-css-extract-plugin
export var adjustmentEditContainer = "player-points-adjustment-module--adjustment-edit-container--22grf";
export var adjustmentText = "player-points-adjustment-module--adjustment-text--JIgHi";
export var bodyBase = "player-points-adjustment-module--body-base--q8zqI player-points-adjustment-module--site-font--shSF+";
export var bodyLarge = "player-points-adjustment-module--body-large--q4dzZ player-points-adjustment-module--body-base--q8zqI player-points-adjustment-module--site-font--shSF+";
export var bodyLargeBold = "player-points-adjustment-module--body-large-bold--EkxF2 player-points-adjustment-module--body-base--q8zqI player-points-adjustment-module--site-font--shSF+";
export var bodyRegular = "player-points-adjustment-module--body-regular--+8bcZ player-points-adjustment-module--body-base--q8zqI player-points-adjustment-module--site-font--shSF+";
export var bodyRegularBold = "player-points-adjustment-module--body-regular-bold--9bylh player-points-adjustment-module--body-base--q8zqI player-points-adjustment-module--site-font--shSF+";
export var bodySmall = "player-points-adjustment-module--body-small--5eDcJ player-points-adjustment-module--body-base--q8zqI player-points-adjustment-module--site-font--shSF+";
export var bodySmallBold = "player-points-adjustment-module--body-small-bold--1O+2L player-points-adjustment-module--body-base--q8zqI player-points-adjustment-module--site-font--shSF+";
export var borderTop = "player-points-adjustment-module--border-top--ed2mf";
export var breakWordContainer = "player-points-adjustment-module--break-word-container--71bWv";
export var buttonIconBase = "player-points-adjustment-module--button-icon-base--k2FpM";
export var clickLink = "player-points-adjustment-module--click-link--jQz2s";
export var dropdownBase = "player-points-adjustment-module--dropdown-base--okjKz";
export var dropdownSelectBase = "player-points-adjustment-module--dropdown-select-base--meTZ1 player-points-adjustment-module--text-input--+ViM1";
export var flexCol = "player-points-adjustment-module--flex-col--DGrq9";
export var formErrorMessage = "player-points-adjustment-module--form-error-message--EWMuP";
export var h3 = "player-points-adjustment-module--h3--iy+Z3";
export var h4 = "player-points-adjustment-module--h4--SwDSK";
export var hoverLink = "player-points-adjustment-module--hover-link--3UK7D";
export var hoverRow = "player-points-adjustment-module--hover-row--CfeuS";
export var membershipContainer = "player-points-adjustment-module--membership-container--Bb8Ns player-points-adjustment-module--flex-col--DGrq9 player-points-adjustment-module--primary-border--QlfOT";
export var membershipHeader = "player-points-adjustment-module--membership-header--zUIyd";
export var membershipHeading = "player-points-adjustment-module--membership-heading--dVnI2";
export var membershipLabel = "player-points-adjustment-module--membership-label--ZamR2";
export var moreFiltersBorderClass = "player-points-adjustment-module--more-filters-border-class--TCLBk";
export var pageBg = "player-points-adjustment-module--page-bg--lLDho";
export var pointer = "player-points-adjustment-module--pointer--op5Rm";
export var primaryBorder = "player-points-adjustment-module--primary-border--QlfOT";
export var shadowBoxLight = "player-points-adjustment-module--shadow-box-light--0nmLI";
export var siteFont = "player-points-adjustment-module--site-font--shSF+";
export var slideDownAndFade = "player-points-adjustment-module--slideDownAndFade--5pBH3";
export var slideLeftAndFade = "player-points-adjustment-module--slideLeftAndFade--Bbb1S";
export var slideRightAndFade = "player-points-adjustment-module--slideRightAndFade--VKJFZ";
export var slideUpAndFade = "player-points-adjustment-module--slideUpAndFade--buztU";
export var statusDecoration = "player-points-adjustment-module--status-decoration--bQtkb";
export var textInput = "player-points-adjustment-module--text-input--+ViM1";
export var textInverted = "player-points-adjustment-module--text-inverted--uRhgi";
export var textMediumDark = "player-points-adjustment-module--text-medium-dark--wWLFh";
export var tooltip = "player-points-adjustment-module--tooltip--03I40";
export var tooltipFont = "player-points-adjustment-module--tooltipFont--46NUI";
export var tooltipTitleCloseButton = "player-points-adjustment-module--tooltip-title-close-button--L1geb player-points-adjustment-module--unstyled-button--di1b5";
export var unstyledButton = "player-points-adjustment-module--unstyled-button--di1b5";