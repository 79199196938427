// extracted by mini-css-extract-plugin
export var adjustmentEditContainer = "player-points-player-module--adjustment-edit-container--QWg0F";
export var adjustmentText = "player-points-player-module--adjustment-text--35dd-";
export var bodyBase = "player-points-player-module--body-base--Mkm3k player-points-player-module--site-font--ci3Dj";
export var bodyLarge = "player-points-player-module--body-large--G8ofk player-points-player-module--body-base--Mkm3k player-points-player-module--site-font--ci3Dj";
export var bodyLargeBold = "player-points-player-module--body-large-bold--Mt3DN player-points-player-module--body-base--Mkm3k player-points-player-module--site-font--ci3Dj";
export var bodyRegular = "player-points-player-module--body-regular--o+Ujk player-points-player-module--body-base--Mkm3k player-points-player-module--site-font--ci3Dj";
export var bodyRegularBold = "player-points-player-module--body-regular-bold--ofwor player-points-player-module--body-base--Mkm3k player-points-player-module--site-font--ci3Dj";
export var bodySmall = "player-points-player-module--body-small--lWlO0 player-points-player-module--body-base--Mkm3k player-points-player-module--site-font--ci3Dj";
export var bodySmallBold = "player-points-player-module--body-small-bold--9Nrz6 player-points-player-module--body-base--Mkm3k player-points-player-module--site-font--ci3Dj";
export var borderTop = "player-points-player-module--border-top--hoTHP";
export var breakWordContainer = "player-points-player-module--break-word-container--2JK8k";
export var buttonIconBase = "player-points-player-module--button-icon-base--WlXOG";
export var clickLink = "player-points-player-module--click-link--ov7sp";
export var dropdownBase = "player-points-player-module--dropdown-base--tDKog";
export var dropdownSelectBase = "player-points-player-module--dropdown-select-base--xRhOa player-points-player-module--text-input--4kE8A";
export var flexCol = "player-points-player-module--flex-col--ohMds";
export var formErrorMessage = "player-points-player-module--form-error-message--whPCH";
export var h3 = "player-points-player-module--h3--9RItK";
export var h4 = "player-points-player-module--h4--V3a6a";
export var hoverLink = "player-points-player-module--hover-link--g2uU7";
export var hoverRow = "player-points-player-module--hover-row--ffd8N";
export var linkButton = "player-points-player-module--link-button--+EZ0x player-points-player-module--unstyled-button--uIEJC";
export var membershipContainer = "player-points-player-module--membership-container--FSXTn player-points-player-module--flex-col--ohMds player-points-player-module--primary-border--LOF8v";
export var membershipHeader = "player-points-player-module--membership-header--ZUKj-";
export var membershipHeading = "player-points-player-module--membership-heading--H3OxW";
export var membershipLabel = "player-points-player-module--membership-label--neNky";
export var moreFiltersBorderClass = "player-points-player-module--more-filters-border-class--85crU";
export var pageBg = "player-points-player-module--page-bg--39syC";
export var playerInfoItem = "player-points-player-module--player-info-item--SCys-";
export var playerInfoItemLabel = "player-points-player-module--player-info-item-label--CsMrG";
export var playerInfoItemValue = "player-points-player-module--player-info-item-value--f+az3";
export var pointer = "player-points-player-module--pointer--bJkph";
export var primaryBorder = "player-points-player-module--primary-border--LOF8v";
export var shadowBoxLight = "player-points-player-module--shadow-box-light--rnYR5";
export var siteFont = "player-points-player-module--site-font--ci3Dj";
export var slideDownAndFade = "player-points-player-module--slideDownAndFade--qPBem";
export var slideLeftAndFade = "player-points-player-module--slideLeftAndFade--SPI7G";
export var slideRightAndFade = "player-points-player-module--slideRightAndFade--jzA9X";
export var slideUpAndFade = "player-points-player-module--slideUpAndFade--r+IBM";
export var statusDecoration = "player-points-player-module--status-decoration--x0Vxa";
export var textInput = "player-points-player-module--text-input--4kE8A";
export var textInverted = "player-points-player-module--text-inverted--exy7f";
export var textMediumDark = "player-points-player-module--text-medium-dark---VGnV";
export var tick = "player-points-player-module--tick--LL0wp";
export var tooltip = "player-points-player-module--tooltip--K8-zU";
export var tooltipFont = "player-points-player-module--tooltipFont--I2j9i";
export var tooltipTitleCloseButton = "player-points-player-module--tooltip-title-close-button--srtFV player-points-player-module--unstyled-button--uIEJC";
export var unstyledButton = "player-points-player-module--unstyled-button--uIEJC";