import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClickAwayListener } from '@material-ui/core'
import { Tooltip as MuiTooltip, Grid } from '@material-ui/core'
import classNames from 'classnames'
import Icon from '../icon/icon'
import { Body } from '../typography/typography'
import * as styles from './player-points-adjustment.module.less'

interface AdjustmentComponentProps {
  adjustmentPoints: number | null
  adjustmentDescription: string | null
}

export const AdjustmentComponent = ({
  adjustmentPoints,
  adjustmentDescription
}: AdjustmentComponentProps) => {
  const [tooltip, setTooltip] = useState(false)

  return (
    <ClickAwayListener onClickAway={() => setTooltip(false)}>
      <div>
        <MuiTooltip
          classes={{ tooltip: styles.tooltip }}
          title={
            <TooltipTitle children={adjustmentDescription} onClose={() => setTooltip(false)} />
          }
          onClose={() => setTooltip(false)}
          open={tooltip}
          onClick={() => {
            setTooltip(true)
          }}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <Grid
            container
            alignItems="center"
            onClick={adjustmentDescription ? () => setTooltip(true) : undefined}
            className={classNames({ [styles.adjustmentEditContainer]: adjustmentDescription })}
          >
            <span className={styles.adjustmentText}>{adjustmentPoints}</span>
            {adjustmentDescription && (
              <Icon name="md-tooltip-annotation" spacing={{ margins: { xxs: 'left' } }} />
            )}
          </Grid>
        </MuiTooltip>
      </div>
    </ClickAwayListener>
  )
}

const TooltipTitle = ({
  children,
  onClose
}: {
  children: React.ReactNode
  onClose: () => void
}) => {
  const { t } = useTranslation()

  return (
    <div>
      <button onClick={onClose} className={styles.tooltipTitleCloseButton}>
        <Icon name="sm-close" className={styles.closeIcon} />
      </button>
      <Grid container justify="space-between">
        <Body size="lg" color={Body.color.SEMI_DARK_GREY}>
          {t('reason for points update')}:
        </Body>
      </Grid>
      <Body size="lg" spacing={{ margins: { xs: 'top' } }}>
        {children}
      </Body>
    </div>
  )
}
